.filter-single-widget {
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid var(--color-border);
  padding-top: 0;

  &:first-child {
    margin-top: 0 !important;
}

  input,
  select {
    border: var(--border-width) solid var(--color-border);
}


  &.widget_search {
    padding: 0;
    border: 0 none;
    .title {
      margin: 0;
  }
}

  p {
    font-size: 16px;
    line-height: 28px;
    color: #717173;
}


  // job List Style
  ul {
    padding-left: 0;
    margin-bottom: -10px;
    list-style: none;
    padding-right: 0;
    ul {
      margin-bottom: 0;
  }
    li {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 28px;
      color: #717173;
      a {
        transition: all 0.3s ease 0s;
        text-decoration: none;
        color: var(--color-heading);
        display: initial;
        &:hover {
          color: var(--color-primary);
      }
    }
  }
}

  &.widget_filter {

    ul {
      li {
        a {
          display: flex;
          justify-content: space-between;
      }
    }
  }
}


  &.widget_recent_comments ,
  &.widget_recent_entries ,
  &.widget_archive ,
  &.widget_categories,
  &.widget_meta ,
  &.widget_pages,
  &.widget_nav_menu {
    ul {
      li {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-body);
        a {
          color: var(--color-body);
          @extend %transition;
          &:hover {
            color: var(--color-primary);
        }
      }
        span {
          @extend %transition;
          color: var(--color-body);
      }
        &:hover {
          a {
            span {
              color: var(--color-primary);
          }
        }
      }
        & + li {
          border-top: 1px solid var(--color-border);
      }
    }
  }
}

  &.widget_recent_comments ,
  &.widget_recent_entries ,
  &.widget_archive ,
  &.widget_categories,
  &.widget_meta ,
  &.widget_pages {
    > ul {
      > li {
        margin-top: 0;
        margin-bottom: 0;
        &:first-child {
          margin-top: 0;
      }
        &:last-child {
          margin-bottom: 10px;
      }
    }
  }
}

  &.widget_recent_entries {
    .inner {
      a {
        color: var(--color-white);
        margin-bottom: 4px;
    }
      span {
        color: var(--color-body);
        font-size: 14px;
        letter-spacing: 0.5px;
    }
  }
}





}